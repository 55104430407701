/**
 * VideoDecode wait frame num.
 */
export const VIDEO_DECODE_WAIT_FRAME = 3;
/**
 * NAL unit length. [ 0, 0, 0, 1 ]
 */
export const NALU_HEADER_LENGTH = 4;
/**
 * Web safe font.
 */
export const WEB_SAFE_FONT = 'Arial';
/**
 * Default render canvas size.
 */
export const DEFAULT_CANVAS_SIZE = 2560;
/**
 * Default WebGL ContextAttributes
 */
export const WEBGL_CONTEXT_ATTRIBUTES = {
  depth: false,
  stencil: false,
  antialias: false,
};
